"use client";
import React, { createContext, useContext, ReactNode, useState, useCallback } from "react";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";
import { DebounceRecord, Quote } from "@/shared/types/Quote.interface";
import { PetPlan } from "@/shared/hooks/usePetPlans";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { ErrorIdType, SpotErrorMessage } from "../types/SpotAPI";
import { useDeepCompareMemo } from "@react-hookz/web";
import { Stripe, StripeElements, ExpressPaymentType } from "@stripe/stripe-js";
import { StripePaymentElementError } from "@/quote-ptz-us/src/components/StripeCheckout/StripeUtils";
import { Breakpoint } from "../hooks/useBreakpoint";

export interface AppStateInterface {
    isMultiPetLinked?: boolean;
    isApplyAllHidden?: boolean;
    quoteQuery?: UseQueryResult<Quote | undefined, Error>;
    updateQuote?: UseMutationResult<Quote, Error, Quote, unknown>;
    isQuoteUpdating?: boolean;
    petPlans?: UseQueryResult<Record<string, PetPlan[]>, Error>;
    calculatedPriceData?: {
        preDiscountPrice: number;
        discountAmount: number;
        finalPrice: number;
        activePetPriceData: {
            preDiscountPrice: number;
            discountAmount: number;
            finalPrice: number;
        };
    };
    formID: string;
    currentStepID?: string;
    underwriter?: PetUnderwriterType;
    billingFrequency?: "monthly" | "yearly";
    isAnnualBilling: boolean;
    asyncErrors?: SpotErrorMessage[];
    hasUnknownError?: boolean;
    mockBillingError?: ErrorIdType;
    stripe?: Stripe | null;
    stripeElements?: StripeElements | null;
    stripeExpressPaymentType?: ExpressPaymentType | undefined;
    stripePaymentElementError?: StripePaymentElementError | undefined;
    stripeAvailablePaymentMethods?: Record<string, boolean>;
    showCreditCardFields?: boolean;
    showBillingSubmitButton?: boolean;
    showLoaderDialog?: boolean;
    formContinueButtonRef?: React.RefObject<HTMLButtonElement>;
    debounceObject?: DebounceRecord;
    breakpoint?: Breakpoint;
}

interface AppLayerContextInterface {
    appState: AppStateInterface;
    updateAppState: (stateUpdate: Partial<AppStateInterface>) => void;
}

interface AppLayerProviderProps {
    children: ReactNode;
    formID: string;
}

export const AppLayerContext = createContext<AppLayerContextInterface | undefined>(undefined);

function AppLayerProvider({ children, formID }: AppLayerProviderProps) {
    const [appState, setAppState] = useState<AppStateInterface>({
        isAnnualBilling: false,
        isMultiPetLinked: true,
        isApplyAllHidden: false,
        asyncErrors: [],
        formID: formID,
        showCreditCardFields: false,
        showBillingSubmitButton: false
    });

    const updateAppState = useCallback((stateUpdate: Partial<AppStateInterface>) => {
        setAppState(prevState => {
            // Perform a deep comparison to prevent unnecessary state updates
            if (isEqual(prevState, { ...prevState, ...stateUpdate })) {
                return prevState; // No update needed
            }
            return { ...prevState, ...stateUpdate }; // Update needed
        });
    }, []);

    const value = useDeepCompareMemo(() => ({ appState, updateAppState }), [appState, updateAppState]);

    return <AppLayerContext.Provider value={value}>{children}</AppLayerContext.Provider>;
}

function useAppLayerContext() {
    const context = useContext(AppLayerContext);
    if (!context) {
        throw new Error("useAppLayerContext must be used within an AppLayerProvider");
    }
    return context;
}

export { AppLayerProvider, useAppLayerContext };
