"use client";
import { useQuery } from "@tanstack/react-query";
import { Content, fetchOneEntry, isEditing, BuilderContent } from "@builder.io/sdk-react/edge";
import { PublicConfig } from "@/shared/PublicConfig";
import { useSearchParams } from "next/navigation";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PersonalizationAttributes } from "@/shared/types/PersonalizationAttributes";
import { Dialog, DialogClose, DialogContent, DialogHeader } from "@/shared/components/ui";
import Link from "next/link";
import isEqual from "react-fast-compare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import dynamic from "next/dynamic";
import { JSONFormProps } from "@/shared/components/JSONForm/JSONForm";
import { FieldValues } from "react-hook-form";
import { PartialQuoteSchema, Quote } from "../types/Quote.interface";
import { BuilderUtils, BuilderDataType } from "../utils/BuilderUtils";
import { useDirtyValues } from "@/shared/hooks/useDirtyValues";
import { UIUtils } from "../utils/UIUtils";
import { TrustPilotData } from "@/quote-ptz-us/src/components/TrustPilotCarousel/TrustPilotTypes";

// Dynamic import for JSONForm component
const JSONForm = dynamic(() => import("@/shared/components/JSONForm/JSONForm"));
const TrustPilotCarousel = dynamic(() => import("@/quote-ptz-us/src/components/TrustPilotCarousel"));

export interface CustomizationSlotProps {
    type:
        | "co-brand"
        | "form-header"
        | "form-footer"
        | "modal"
        | "symbol"
        | "announcement-bar"
        | "above-cta"
        | "above-disclaimer"
        | "terms-disclaimer"
        | "thank-you"
        | "thank-you-sidebar"
        | "above-preventive"
        | "icon-grid";
    modalData?: FieldValues;
    formData?: FieldValues;
    updateData?: (dataArray: { type: BuilderDataType; data: FieldValues }[]) => Promise<void>;
    data?: Quote;
    formId?: string;
    formStepId?: string;
    fallback?: React.ReactNode;
}

export function CustomizationSlot(props: CustomizationSlotProps) {
    const { type, modalData, formData, updateData, data: formValues, formId, formStepId, fallback } = props;
    const [content, setContent] = useState<BuilderContent | null>(null);
    const { dirtyValues } = useDirtyValues();
    const data = useMemo(() => UIUtils.deepMerge(formValues ?? {}, dirtyValues ?? {}, PartialQuoteSchema).mergedBase, [formValues, dirtyValues]);
    const searchParams = useSearchParams();
    const lastAttributes = useRef<PersonalizationAttributes>();
    const editing = isEditing(searchParams);
    const isEditingType = editing && searchParams.get("builder.preview") === props.type;
    const [modalOpen, setModalOpen] = useState(false);
    const attributes = BuilderUtils.getBuilderAttributes({ data, formId, formStepId });
    attributes.queryParamsStringLowerCase = searchParams.toString().toLowerCase();

    const shouldDisplayModal = useCallback(
        (modalGroupID: string): boolean => {
            const modalStates = modalData ?? {};
            return !modalStates[modalGroupID];
        },
        [modalData]
    );

    const fetchBuilderContent = async () => {
        if (isEqual(attributes, lastAttributes.current)) {
            return content;
        }
        lastAttributes.current = attributes;

        try {
            const result = await fetchOneEntry({
                apiKey: PublicConfig.BUILDER_IO_PUBLIC_API_KEY,
                model: type,
                userAttributes: attributes
            });
            return result;
        } catch (error) {
            throw error;
        }
    };

    const {
        data: builderData,
        error,
        isSuccess
    } = useQuery({
        queryKey: ["builderContent", type, formId, formStepId, attributes],
        queryFn: fetchBuilderContent,
        enabled: true,
        retry: false,
        staleTime: 60 * 1000 * 5
    });

    useEffect(() => {
        if (isSuccess) {
            if (!!builderData) {
                setContent(builderData);
                const formGroupID = builderData?.data?.displayOnceGroupId ?? builderData?.id;
                if (builderData?.data?.displayOnce && formGroupID && updateData) {
                    if (shouldDisplayModal(formGroupID)) {
                        setModalOpen(true);
                    }
                }
            } else {
                setContent(null);
            }
        }
    }, [isSuccess, builderData, updateData, shouldDisplayModal]);

    const handleModalClose = async () => {
        if (editing) return;

        setModalOpen(false);
        const formGroupID = content?.data?.displayOnceGroupId ?? content?.id;
        if (content?.data?.displayOnce && formGroupID && updateData) {
            // If this modal is set to only display once, then make sure we save the state as part of the quote extra data.
            await updateData([{ type: "modal", data: { ...modalData, [formGroupID]: true } }]);
        }
    };

    const handleFormSubmit = async (jsonFormProps: JSONFormProps, newFormData: any) => {
        if (updateData) {
            const formUpdateData: { type: BuilderDataType; data: FieldValues } = { type: "form", data: { ...formData, [jsonFormProps.formID]: newFormData } };
            const updates = [formUpdateData];

            if (type === "modal") {
                const formGroupID = content?.data?.displayOnceGroupId ?? content?.id;
                const modalUpdateData: { type: BuilderDataType; data: FieldValues } = { type: "modal", data: { ...modalData, [formGroupID]: true } };
                updates.push(modalUpdateData);
            }

            await updateData(updates);
            if (type === "modal") {
                setModalOpen(false);
            }
        }
    };

    const JSONFormCustomComponent = {
        name: "JSON Form",
        inputs: [
            { name: "formID", type: "text", friendlyName: "Form ID", required: true },
            { name: "dataSchema", type: "text", friendlyName: "Data Schema" },
            { name: "uiSchema", type: "text", friendlyName: "UI Schema" },
            { name: "submitButtonTitle", type: "text", friendlyName: "Submit Button Title" }
        ],
        component: (props: JSONFormProps) => <JSONForm {...props} initialData={formData?.[props.formID]} onSubmit={data => handleFormSubmit(props, data)} />
    };

    const TrustPilotCustomComponent = {
        name: "TrustPilot Carousel",
        inputs: [{ name: "data", type: "object", friendlyName: "TrustPilot Data" }],
        component: (props: { data: TrustPilotData }) => <TrustPilotCarousel data={props.data} />
    };

    const renderContent = () => {
        if (!content) {
            return null;
        }

        return (
            <div>
                <Content
                    apiKey={PublicConfig.BUILDER_IO_PUBLIC_API_KEY}
                    content={content}
                    model={props.type}
                    linkComponent={Link}
                    data={lastAttributes.current}
                    customComponents={[TrustPilotCustomComponent, JSONFormCustomComponent]}
                />
            </div>
        );
    };

    const renderModal = () => {
        return (
            <Dialog open={editing || modalOpen} modal={!editing}>
                <DialogContent className={`max-w-screen-md ${content?.data?.noPadding ? "md:p-0" : ""} ${editing ? "border-8" : ""}`}>
                    {content?.data?.allowManualClose && (
                        <DialogHeader className="m-0 contents p-0">
                            <DialogClose onClick={handleModalClose} className="z-30 text-content-primary-invert">
                                <FontAwesomeIcon icon={faTimes} className="inline-block size-6" size="lg" />
                            </DialogClose>
                        </DialogHeader>
                    )}
                    {renderContent()}
                </DialogContent>
            </Dialog>
        );
    };

    if ((!!error && !!fallback) || (!content && !!fallback)) {
        return <>{fallback}</>;
    }

    if (!editing && !content) {
        return null;
    }

    if (editing && !isEditingType) {
        return null;
    }

    return type === "modal" ? renderModal() : renderContent();
}
